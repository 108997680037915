<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-form label-width="80px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="用户姓名">
              <el-input v-model="queryInfo.condition.name" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="queryInfo.condition.phone" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="getListData">搜索</el-button>
            <el-button @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 用户列表区域 -->
      <div>
        <el-row style="width: 100%">
          <el-table
            :data="tableData"
            border
            fit
            highlight-current-row
            style="width: 100%"
            class="heightfix"
          >
            <el-table-column label="编号" align="center" prop="id">
              <template slot-scope="scope">
                <span>{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <!--              <el-table-column label="反馈编码" align="center" prop="反馈编码">-->
            <!--                <template slot-scope="scope">-->
            <!--                  <span>{{ scope.row.feedbackCode }}</span>-->
            <!--                </template>-->
            <!--              </el-table-column>-->
            <!--              <el-table-column label="用户编码" align="center" prop="用户编码">-->
            <!--                <template slot-scope="scope">-->
            <!--                  <span>{{ scope.row.memberCode }}</span>-->
            <!--                </template>-->
            <!--              </el-table-column>-->
            <el-table-column label="用户姓名" align="center" prop="用户姓名">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="手机号">
              <template slot-scope="scope">
                <span>{{ scope.row.phone }}</span>
              </template>
            </el-table-column>
            <el-table-column label="反馈内容" align="center" prop="反馈内容">
              <template slot-scope="scope">
                <span>{{ scope.row.feedbackContent }}</span>
              </template>
            </el-table-column>
            <el-table-column label="图片" align="center" prop="url">
              <template slot-scope="scope">
                <el-image
                  style="width: 50px;height: 50px"
                  :src="
                    scope.row.picture
                      ? JSON.parse(scope.row.picture)[0].url
                      : ''
                  "
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="创建时间">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="detail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog title="详情" :visible.sync="detailShow" width="600px">
      <div>
        <el-row :gutter="20">
          <el-form label-width="80px">
            <el-col :span="24">
              <el-form-item label="用户姓名">
                <el-input
                  v-model="detailInfo.name"
                  readonly
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="手机号">
                <el-input
                  v-model="detailInfo.phone"
                  readonly
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="反馈内容">
                <el-input
                  v-model="detailInfo.feedbackContent"
                  readonly
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="图片">
                <div class="picture">
                  <el-image
                    v-for="item in detailInfo.url"
                    v-if="item.type == 'image'"
                    :src="item.url"
                    :preview-src-list="[item.url]"
                    style="width: 150px;height: 150px"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="视频">
                <div class="picture">
                  <video
                    v-for="item in detailInfo.url"
                    v-if="item.type == 'video'"
                    :src="item.url"
                    controls
                    style="width: 150px;height: 150px"
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailShow = false">取 消</el-button>
        <el-button type="primary" @click="detailShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 306, //表格高度
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          id: ""
        }
      },
      total: 0,
      editUserForm: {},
      // 详情弹窗
      detailShow: false,
      detailInfo: {}
    };
  },
  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      this.queryInfo.condition.name = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/feedback/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    detail(row) {
      this.detailShow = true;
      this.detailInfo = row;
    },

    //重置
    reset() {
      this.queryInfo.condition = {};
      this.getListData();
    },

    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/feedback/list", that.queryInfo)
        .then(function(response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
            that.tableData.forEach((item, i) => {
              that.tableData[i].url = JSON.parse(item.picture);
            });
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 306;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;

    .el-select > .el-input {
      width: 200px;
    }

    .header-search-box .header-search-input {
      width: 325px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .heightfix {
      overflow-y: scroll;
      max-height: calc(100vh - 306px);
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}

/deep/ .el-scrollbar__view {
  min-width: 100%;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: auto;
  height: calc(100%); //多出来的20px是横向滚动条默认的样式
  width: 100%;
}

/deep/ .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
  display: inline-block;
}

.picture {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
